/**
 * class Lightboxes
 * @author Stef Funaro
 *
 * https://biati-digital.github.io/glightbox/
 * https://github.com/biati-digital/glightbox/blob/master/README.md
 *
 * npm install glightbox
 *
 *
 */
import { App } from "../App";
import GLightbox from 'glightbox';

export class Lightboxes {

	constructor (protected app: App) {
		console.log("Lightbox constructor");

		const lightbox = GLightbox({});
	}

}

/**
 * Forms class
 * Mettre ici les trucs généraux qui concernent les formulaires.
 *
 * @author Stef Funaro
 *
 */
import { App } from "../App";

export class Forms {
	constructor (protected app: App) {
		console.log("Henri Forms constructor");
	}

	init (): Forms {
		console.log("Henri Forms init!");
		return this;
	}
}

import { wait } from "./timing";

export function debounce(fn, ms) {
  let canRun = true;
  let midCalled = undefined;

  return async (...args) => {
    if (!canRun) {
      midCalled = args;
      return;
    }

    canRun = false;

    fn(...args);

    await wait(ms);

    if (midCalled) {
      fn(...midCalled);
      midCalled = undefined;
    }

    canRun = true;
  };
}

/**
 * App class
 * Classe principale.
 *
 * @author Stef Funaro
 *
 */
import { Layout } from "./ui-controllers/Layout";
import { Animation } from "./ui-controllers/Animation";
import { Forms } from "./ui-controllers/Forms";
import { Navigation } from "./ui-controllers/Navigation";
import { SocialShareLinks } from "./components/SocialShareLinks";
import { Slide } from "./animations/Slide";
import { Lightboxes } from "./components/Lightboxes";

export class App {
  // Properties
  readonly layout: Layout;
  readonly animation: Animation;
  readonly forms: Forms;
  readonly navigation: Navigation;
  private readonly _mainContainer: HTMLDivElement;

  constructor() {
    console.log("Henri App Constructor");

    // Main container
    const mainContainerCheck: HTMLDivElement | null = document.querySelector("div#dx-page-body");
    if (mainContainerCheck === null) {
      console.error("Main container is not present!");
      return;
    }
    this._mainContainer = mainContainerCheck;
    // Ui controllers
    this.animation = new Animation(this);
    this.layout = new Layout(this);
    this.forms = new Forms(this);
    this.navigation = new Navigation(this);
  }

  /**
   * init
   */
  init(): void {
    console.log("Henri App init");
    this.initControllers();
    this.initComponents();
  }

  /**
   * initControllers
   */
  initControllers(): void {
    console.log("Henri App controllers init");
    // Controllers
    this.animation.init();
    this.layout.init();
    this.forms.init();
    this.navigation.init();
  }

  /**
   * initComponents
   */
  initComponents(): void {
    console.log("Henri App components init");
    // Swiper
    // EffetMonstre: Swipers are called within Stimulus controller now!
    // const swipers: Swipers = new Swipers(this);
    // swipers.init();
    // Social Share
    const socialShare: SocialShareLinks = new SocialShareLinks();
    socialShare.init();

    const lightboxes = new Lightboxes(this);

    // this.fakecontent();
    this.offcanvas();
    this.showMoreBoxes();
    this.productsImagesViewer();
  }

  offcanvas() {
    const offcanvases = document.querySelectorAll(`.offcanvas[data-bs-scroll="false"]`);

    for (const elem of offcanvases) {
      elem.addEventListener("hide.bs.offcanvas", function () {
        document.body.classList.toggle("offcanvas-open");
      });
      elem.addEventListener("show.bs.offcanvas", function () {
        document.body.classList.toggle("offcanvas-open");
      });
    }
  }

  fakecontent(): void {
    /*
        Fake dupplicate de boites, à dynamiser avec le back-end.
         */
    const carrouselMob = document.querySelector(".carousel-mobilier");
    if (carrouselMob !== null) {
      const carrouselMobBtn = carrouselMob.querySelector(".js-btn-see-more");
      if (carrouselMobBtn !== null) {
        carrouselMobBtn.addEventListener("click", function (e) {
          e.preventDefault();
          let lastbox = carrouselMob.querySelector(".product-box");
          if (lastbox) {
            for (var i = 0; i < 4; i++) {
              let box = carrouselMob.querySelector(".product-related-box")!;
              let clone = lastbox.cloneNode(true);
              box.appendChild(clone);
            }
          }
        });
      }
    }
  }

  /**
   * Boites avec bouton lire plus
   */
  showMoreBoxes(): void {
    const instance = this;
    const showMoreBtns = document.querySelectorAll(".js-btn-open-more");
    showMoreBtns.forEach(function (btnEl: HTMLElement) {
      btnEl.addEventListener("click", function (e) {
        e.preventDefault();
        console.log("click", this.dataset.target);
        let targetStr = this.dataset.target as string;
        let target = document.querySelector(targetStr);
        this.classList.add("d-none");
        if (target != null) {
          // target.classList.remove("open-more-content-hide");
          Slide.down(target, 500);
          instance.navigation.scrollToElem(target as HTMLElement);
        }
        //Less btn
        let closeEl = document.querySelector(`.js-btn-open-less[data-target="${targetStr}"]`);
        closeEl?.classList.remove("d-none");
      });
    });
    const showLessBtns = document.querySelectorAll(".js-btn-open-less");
    showLessBtns.forEach(function (btnEl: HTMLElement) {
      btnEl.addEventListener("click", function (e) {
        e.preventDefault();
        let targetStr = this.dataset.target as string;
        let target = document.querySelector(targetStr);
        this.classList.add("d-none");
        if (target != null) {
          Slide.up(target, 500);
        }
        //openBtn
        let openEl = document.querySelector(`.js-btn-open-more[data-target="${targetStr}"]`);
        openEl?.classList.remove("d-none");
      });
    });
  }

  productsImagesViewer(): void {
    let productsMediaViewerEl = this.mainContainer.querySelector(".produit-details-media-box")!;

    if (!productsMediaViewerEl) {
      return;
    }

    const bigImgDiv: HTMLElement = productsMediaViewerEl.querySelector(".big-media-box.product-img-box")!;
    const bigImg: HTMLImageElement = productsMediaViewerEl.querySelector(".big-media-box.product-img-box img")!;
    const bigImgCreditDiv: HTMLElement = bigImgDiv.querySelector(".product-img-box-credit")!;
    const bigSketchup: HTMLElement = productsMediaViewerEl.querySelector(".big-media-box.product-3d-box")!;

    const thumbImgs = [...productsMediaViewerEl.querySelectorAll(".thumb-item:not(.thumb-sketchup)")];

    const thumbSketchup = productsMediaViewerEl.querySelector(".thumb-item.thumb-sketchup");

    const changeSelectedThumb = (thumbEl: Element) => {
      productsMediaViewerEl.querySelector(".thumb-item.thumb-active")?.classList.remove("thumb-active");
      thumbEl.classList.add("thumb-active");
    };

    const handleThumbImgClick = (thumbEl: HTMLElement) => {
      changeSelectedThumb(thumbEl);

      bigImgDiv.style.display = "flex";
      if (bigSketchup) {
        bigSketchup.style.display = "none";
      }

      if (thumbImgs[0] !== thumbEl) {
        bigImgDiv.classList.remove("product-img-box");
      } else {
        bigImgDiv.classList.add("product-img-box");
      }

      if (bigImgCreditDiv) {
        if (thumbEl.dataset.credit) {
          bigImgCreditDiv.classList.remove("d-none");
          bigImgCreditDiv.innerHTML = `&#169; ${thumbEl.dataset.credit}`;
        } else {
          bigImgCreditDiv.classList.add("d-none");
          bigImgCreditDiv.innerHTML = ``;
        }
      }

      bigImg.src = thumbEl.dataset.imgLarge!;
    };

    const handleThumbSketchupClick = () => {
      bigImgDiv.style.display = "none";
      bigSketchup.style.display = "block";

      changeSelectedThumb(thumbSketchup!);
    };

    for (const thumbImg of thumbImgs) {
      if (!(thumbImg instanceof HTMLElement)) {
        return;
      }
      thumbImg.addEventListener("click", (e) => {
        e.preventDefault();
        handleThumbImgClick(thumbImg);
      });
    }

    if (thumbSketchup) {
      thumbSketchup.addEventListener("click", (e) => {
        e.preventDefault();
        handleThumbSketchupClick();
      });
    }
  }

  public get mainContainer(): HTMLDivElement {
    return this._mainContainer;
  }
}

/**
 * Gradient animation
 */

import gsap from "gsap";

export class GradientsBgBtn {

    constructor(el: HTMLAnchorElement) {
        const instance = this;
        console.log("constructor bg btn!");
        addEventListener("mousemove", (event) => {

            const rect = el.getBoundingClientRect();
            const xPos = ((event.clientX - rect.left) / rect.width) * 100;
            const yPos = ((event.clientY - rect.top) / rect.height) * 100;

            // console.log("bg",xPos,yPos);
            el.style.background =  'radial-gradient(at ' + xPos + '% ' + yPos + '%, #b42155, #0a9c99)';
            // el.style.background =  'linear-gradient(90deg, #b42155 '+mouseXpercentage+'%, #0a9c99)';
        });
    }

    public update() {

    }



}
